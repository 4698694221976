import { createRouter, createWebHistory } from 'vue-router'
import HomeView from "@/views/HomeView.vue";
import VerwaltungsView from "@/views/VerwaltungsView.vue";
import KontaktView from "@/views/KontaktView.vue";
import UeberunsView from "@/views/UeberunsView.vue";
import FraktionView from "@/views/FraktionView.vue";
import AktuellesView from "@/views/AktuellesView.vue";
import KalenderView from "@/views/KalenderView.vue";
import ImpressumView from "@/views/ImpressumView.vue";
import DatenschutzView from "@/views/DatenschutzView.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/kalender',
    name: 'kalender',
    component: KalenderView
  },
  {
    path: '/admin',
    name: 'admin',
    component: VerwaltungsView

  },
  {
    path: '/kontakt',
    name: 'kontakt',
    component: KontaktView
  },
  {
    path: '/impressum',
    name: 'impressum',
    component: ImpressumView
  },
  {
    path: '/datenschutz',
    name: 'datenschutz',
    component: DatenschutzView
  },
  {
    path: '/ueberuns',
    name: 'ueberuns',
    component: UeberunsView
  },
  {
    path: '/fraktion',
    name: 'fraktion',
    component: FraktionView
  },
  {
    path: '/aktuelles',
    name: 'aktuelles',
    component: AktuellesView
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
