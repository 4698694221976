<template>
  <div v-if="!$store.state.mobile" style="background-color: #faf9f9">
    <HeaderComponent></HeaderComponent>
    <v-row style="width: 100%;" class="mx-0 justify-center">
      <v-col cols="10" class="d-flex">
        <div style="height: 100% ;width: 12px; background-color: #01009a">

        </div>
        <h2 class="ml-2">
          ÜBER UNS
        </h2>
      </v-col>
    </v-row>
    <div style="height: 500px;" class="grey mt-3">
      <v-row style="width: 100%; height: 100%" class="mx-0 justify-center align-center">
        <v-col cols="5">
          <v-img cover style="height: 450px; " src="../assets/uberUns.jpg"></v-img>
        </v-col>
          <v-col cols="5">
            <h3>Herzlich willkommen bei der BG: Bürgergemeinschaft Bad-Sassendorf</h3>
            <br>
            <br>
            <p style="font-size: 16px" class="font-weight-light">
              Wir sind die Bürgergemeinschaft Bad Sassendorf, eine engagierte Gruppe von Bürgerinnen und Bürgern,
              die sich aktiv für die Belange und die Weiterentwicklung unserer schönen Gemeinde einsetzen.
              <br> <br>
              Unser Ziel ist es, das Miteinander zu fördern, Projekte voranzutreiben und die Lebensqualität in Bad Sassendorf zu verbessern.
              Gemeinsam organisieren wir Veranstaltungen, unterstützen lokale Initiativen und setzen uns für eine nachhaltige und lebenswerte Zukunft ein.
              <br> <br>
              Wir laden alle ein, Teil unserer Gemeinschaft zu werden und gemeinsam an der positiven Gestaltung unseres Ortes mitzuwirken.
            </p>
          </v-col>
      </v-row>
    </div>
    <div class="white py-4">
    <v-row style="width: 100%" class="ma-0">
      <v-col cols="3">
        <div style="width: 100%; height: 650px; padding:20px; background-image: linear-gradient(to top, #000099 40%, #0909cd 70%); box-shadow: 2px 2px 5px rgba(0,0,0,0.81)">
          <v-img v-if="loading" style="height: 450px"  :src="vorsitzender?.bild"></v-img>
          <h2 class="text-white text-center mt-4">Vorsitzender</h2>
          <h3 class="text-white text-center mt-1">{{vorsitzender?.vorname}} {{vorsitzender?.name}}</h3>
          <p class="text-white text-center mt-1">
            <a class="text-white" href="mailto:vorsitzender@bg-badsassendorf.de">
            vorsitzender@bg-badsassendorf.de
          </a>
          </p>
        </div>
      </v-col>
      <v-col cols="3">
        <div style="width: 100%; height: 650px; padding:20px; background-image: linear-gradient(to top, #000099 40%, #0909cd 70%); box-shadow: 2px 2px 5px rgba(0,0,0,0.81)">
          <v-img v-if="loading" style="height: 450px"  :src="stellvVorsitzender?.bild"></v-img>
          <h2 class="text-white text-center mt-4">Stellvertretende Vorsitzende</h2>
          <h3 class="text-white text-center mt-1">{{stellvVorsitzender?.vorname}} {{stellvVorsitzender?.name}}</h3>
          <p class="text-white text-center mt-1">
            <a class="text-white" href="mailto:stellvVorsitzender@bg-badsassendorf.de">
              stellvVorsitzender@bg-badsassendorf.de
            </a>
          </p>
        </div>
      </v-col>
      <v-col cols="3">
        <div style="width: 100%; height: 650px; padding:20px; background-image: linear-gradient(to top, #000099 40%, #0909cd 70%); box-shadow: 2px 2px 5px rgba(0,0,0,0.81)">
          <v-img v-if="loading" style="height: 450px"  :src="schriftführer?.bild"></v-img>
          <h2 class="text-white text-center mt-4">Schriftführer</h2>
          <h3 class="text-white text-center mt-1">{{schriftführer?.vorname}} {{schriftführer?.name}}</h3>
          <p class="text-white text-center mt-1">
            <a class="text-white" href="mailto:Schriftfuehrer@bg-badsassendorf.de">
              Schriftfuehrer@bg-badsassendorf.de
            </a>
          </p>
        </div>
      </v-col>
      <v-col cols="3">
        <div style="width: 100%; height: 650px; padding:20px; background-image: linear-gradient(to top, #000099 40%, #0909cd 70%); box-shadow: 2px 2px 5px rgba(0,0,0,0.81)">
          <v-img v-if="loading" style="height: 450px"  :src="schatzmeister?.bild"></v-img>
          <h2 class="text-white text-center mt-4">Schatzmeister</h2>
          <h3 class="text-white text-center mt-1">{{schatzmeister?.vorname}} {{schatzmeister?.name}}</h3>
          <p class="text-white text-center mt-1">
            <a class="text-white" href="mailto:Kassierer@bg-badsassendorf.de">
              kassierer@bg-badsassendorf.de
            </a>
          </p>
        </div>
      </v-col>
    </v-row>
    </div>
    <FooterComponent></FooterComponent>
  </div>
  <div v-if="$store.state.mobile" style="height: 100vh;">
    <v-layout>

      <v-navigation-drawer
          location="right"
          style="background-image: linear-gradient(to top, #000099 10%, #0909cd 70%);color: white"
          v-model="$store.state.drawer"
          temporary
      >
        <DrawerComponent></DrawerComponent>
      </v-navigation-drawer>
      <v-main style="height: 100%">
        <HeaderComponent></HeaderComponent>
        <v-row style="width: 100%; height: 100%" class="mx-0 pb-10 justify-center align-center">
          <v-col cols="12">
            <v-img  style="height: 450px; " src="../assets/uberUns.jpg"></v-img>
          </v-col>
          <v-col cols="12">
            <h3>Herzlich willkommen bei der BG: Bürgergemeinschaft Bad-Sassendorf</h3>
            <br>
            <br>
            <p style="font-size: 16px" class="font-weight-light">
              Wir sind die Bürgergemeinschaft Bad Sassendorf, eine engagierte Gruppe von Bürgerinnen und Bürgern,
              die sich aktiv für die Belange und die Weiterentwicklung unserer schönen Gemeinde einsetzen.
              <br> <br>
              Unser Ziel ist es, das Miteinander zu fördern, Projekte voranzutreiben und die Lebensqualität in Bad Sassendorf zu verbessern.
              Gemeinsam organisieren wir Veranstaltungen, unterstützen lokale Initiativen und setzen uns für eine nachhaltige und lebenswerte Zukunft ein.
              <br> <br>
              Wir laden alle ein, Teil unserer Gemeinschaft zu werden und gemeinsam an der positiven Gestaltung unseres Ortes mitzuwirken.
            </p>
          </v-col>
        </v-row>
        <v-row style="width: 100%" class="ma-0 pb-10 justify-center">
          <v-col cols="11">
            <div style="width: 100%; height: 650px; padding:20px; background-image: linear-gradient(to top, #000099 40%, #0909cd 70%); box-shadow: 2px 2px 5px rgba(0,0,0,0.81)">
              <v-img v-if="loading" style="height: 450px"  :src="vorsitzender?.bild"></v-img>
              <h2 class="text-white text-center mt-4">Vorsitzender</h2>
              <h3 class="text-white text-center mt-1">{{vorsitzender?.vorname}} {{vorsitzender?.name}}</h3>
              <p class="text-white text-center mt-1">
                <a class="text-white" href="mailto:vorsitzender@bg-badsassendorf.de">
                  vorsitzender@bg-badsassendorf.de
                </a>
              </p>
            </div>
          </v-col>
          <v-col cols="11">
            <div style="width: 100%; height: 650px; padding:20px; background-image: linear-gradient(to top, #000099 40%, #0909cd 70%); box-shadow: 2px 2px 5px rgba(0,0,0,0.81)">
              <v-img v-if="loading" style="height: 450px"  :src="stellvVorsitzender?.bild"></v-img>
              <h2 class="text-white text-center mt-4">Stellvertretende Vorsitzende</h2>
              <h3 class="text-white text-center mt-1">{{stellvVorsitzender?.vorname}} {{stellvVorsitzender?.name}}</h3>
              <p class="text-white text-center mt-1">
                <a class="text-white" href="mailto:stellvVorsitzender@bg-badsassendorf.de">
                  stellvVorsitzender@bg-badsassendorf.de
                </a>
              </p>
            </div>
          </v-col>
          <v-col cols="11">
            <div style="width: 100%; height: 650px; padding:20px; background-image: linear-gradient(to top, #000099 40%, #0909cd 70%); box-shadow: 2px 2px 5px rgba(0,0,0,0.81)">
              <v-img v-if="loading" style="height: 450px"  :src="schriftführer?.bild"></v-img>
              <h2 class="text-white text-center mt-4">Schriftführer</h2>
              <h3 class="text-white text-center mt-1">{{schriftführer?.vorname}} {{schriftführer?.name}}</h3>
              <p class="text-white text-center mt-1">
                <a class="text-white" href="mailto:Schriftfuehrer@bg-badsassendorf.de">
                  Schriftfuehrer@bg-badsassendorf.de
                </a>
              </p>
            </div>
          </v-col>
          <v-col cols="11">
            <div style="width: 100%; height: 650px; padding:20px; background-image: linear-gradient(to top, #000099 40%, #0909cd 70%); box-shadow: 2px 2px 5px rgba(0,0,0,0.81)">
              <v-img v-if="loading" style="height: 450px"  :src="schatzmeister?.bild"></v-img>
              <h2 class="text-white text-center mt-4">Schatzmeister</h2>
              <h3 class="text-white text-center mt-1">{{schatzmeister?.vorname}} {{schatzmeister?.name}}</h3>
              <p class="text-white text-center mt-1">
                <a class="text-white" href="mailto:Kassierer@bg-badsassendorf.de">
                  kassierer@bg-badsassendorf.de
                </a>
              </p>
            </div>
          </v-col>
        </v-row>
        <FooterComponent></FooterComponent>
      </v-main>
    </v-layout>


  </div>
</template>
<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import axios from "axios";
import DrawerComponent from "@/components/DrawerComponent.vue";

export default {
  data(){
    return{
      loading: false,
      vorsitzender: null,
      stellvVorsitzender: null,
      schriftführer: null,
      schatzmeister: null
    }
  },
  methods: {
    async getVorsitzender() {
      let response = await axios.get('vorsitzender/1')
      this.vorsitzender = response.data
      console.log(response.data)
    },
    async getStellvVorsitzender() {
      let response = await axios.get('StellvertretenderVorsitzender/1')
      this.stellvVorsitzender = response.data
      console.log(response.data)
    },
    async getSchriftführer() {
      let response = await axios.get('schriftfuehrer/1')
      this.schriftführer = response.data
      console.log(response.data)
    },async getSchatzmeister() {
      let response = await axios.get('schatzmeister/1')
      this.schatzmeister = response.data
      console.log(response.data)
    },
  },
  async mounted() {
    this.loading = false
   await this.getVorsitzender()
   await this.getStellvVorsitzender()
   await this.getSchriftführer()
   await this.getSchatzmeister()
    this.loading = true

  },
  components: {DrawerComponent, FooterComponent, HeaderComponent},

}
</script>

<style >
.white{
  background-color: #faf9f9;
}

</style>
