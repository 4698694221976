<template>
  <div v-if="!$store.state.mobile">
    <HeaderComponent></HeaderComponent>
    <v-card
        :rounded="false"
        class="mx-auto my-12 pa-5 dialogBackground"
        style="background-color: rgb(205,205,205); box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.73)"
        width="1200">
      <v-row class="dialogContent">
        <v-col>
          <v-row class="justify-center">
            <v-col class="d-flex justify-center" cols="10">
              <h1 class="line" style="font-family: 'Dancing Script', 'cursive'"> Termin Vereinbaren</h1>
            </v-col>
            <v-col class="d-flex justify-center mt-n3" cols="10">
              <v-text-field v-model="name" label="Name" variant="underlined"/>
            </v-col>
            <v-col class="d-flex justify-center mt-n3" cols="10">
              <v-text-field v-model="email" label="Email" variant="underlined"/>
            </v-col>
            <v-col class="d-flex justify-center mt-n3" cols="10">
              <v-text-field v-model="telefonnummer" label="Telefonnummer" variant="underlined"/>
            </v-col>
            <v-col class="d-flex justify-center mt-n3" cols="10">
              <v-textarea v-model="text" auto-grow counter
                          label="Nachricht" no-resize style="color: black" variant="underlined"/>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="align-center d-flex pr-16">
          <v-row class="mt-10">
            <v-col cols="12">
              <v-img src="../assets/Logo.svg" style="height: 300px; border-radius: 5px"></v-img>
            </v-col>
            <v-col class=" pt-15" cols="12">
              <h2 class="pt-10">Ihre Anliegen sind uns wichtig!</h2>
              <br>
              <p style="font-size: 16px">Zögern Sie nicht, unser
                Kontaktformular
                zu
                nutzen,
                um
                mit
                uns in Verbindung zu treten und oder teil der Bürgergemeinschaft Bad Sassendorf zu werden.
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row class="mt-10">
            <v-col class="d-flex justify-center" cols="12">
              <v-btn variant="text" @click="create">
                Termin vereinbaren
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>

    <FooterComponent></FooterComponent>

  </div>
  <div v-if="$store.state.mobile"  style="height: 100vh;">
    <v-layout>

      <v-navigation-drawer
          location="right"
          style="background-image: linear-gradient(to top, #000099 10%, #0909cd 70%);color: white"
          v-model="$store.state.drawer"
          temporary
      >
        <DrawerComponent></DrawerComponent>
      </v-navigation-drawer>
      <v-main cl style="height: 100%">
        <HeaderComponent></HeaderComponent>
        <v-card
            :rounded="false"
            class="mx-3 my-12 pa-5 dialogBackground"
            style="background-color: rgb(205,205,205); box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.73)"
            >
          <v-row class="dialogContent">
            <v-col>
              <v-row class="justify-center">
                <v-col class="d-flex justify-center" cols="10">
                  <h1 class="line" style="font-family: 'Dancing Script', 'cursive'"> Termin Vereinbaren</h1>
                </v-col>
                <v-col class="d-flex justify-center mt-n3" cols="10">
                  <v-text-field v-model="name" label="Name" variant="underlined"/>
                </v-col>
                <v-col class="d-flex justify-center mt-n3" cols="10">
                  <v-text-field v-model="email" label="Email" variant="underlined"/>
                </v-col>
                <v-col class="d-flex justify-center mt-n3" cols="10">
                  <v-text-field v-model="telefonnummer" label="Telefonnummer" variant="underlined"/>
                </v-col>
                <v-col class="d-flex justify-center mt-n3" cols="10">
                  <v-textarea v-model="text" auto-grow counter
                              label="Nachricht" no-resize style="color: black" variant="underlined"/>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12">
              <v-row class="mt-10">
                <v-col class="d-flex justify-center" cols="12">
                  <v-btn variant="text" @click="create">
                    Termin vereinbaren
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
        <FooterComponent></FooterComponent>
      </v-main>
    </v-layout>


  </div>

</template>
<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import axios from "axios";
import DrawerComponent from "@/components/DrawerComponent.vue";

export default {
  name: "KontaktView",
  data() {
    return {
      name: '',
      email: '',
      telefonnummer: '',
      text: '',
    }
  },
  components: {DrawerComponent, FooterComponent, HeaderComponent},
  methods: {
    async create() {
      try {
        await axios.post('/kontaktformular', {
          email: this.email,
          telefonnummer: this.telefonnummer,
          name: this.name,
          text: this.text,
        })

        this.email = null
        this.telefonnummer = null
        this.name = null
        this.text = null

      } catch (e) {
        alert(e)
      }
    },
  }
}
</script>

<style scoped>
.dialogBackground {
  position: relative;
  background-image: url("../assets/kontaktformular-background.jpg");
  background-size: cover;
  background-position: center;
}

.dialogBackground::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));

  backdrop-filter: blur(1px); /* Unschärfe hinzufügen */
  z-index: 1;
}

.dialogContent {
  position: relative;
  z-index: 2;
  /* Inhalt des Dialogs */
}


.logo {
  background-image: url("../assets/Logo.svg");
  background-size: cover;

  height: 165px;
}

.v-text-field .v-field-label {
  color: black !important; /* Label immer schwarz */
}

.v-text-field.v-field--notched .v-field-label {
  color: black !important; /* Schwarz, auch wenn das Feld leer ist */
}


</style>
