<template>
  <div class="d-flex justify-center align-center" style="width: 100%; height: 100%">

    <v-row>
      <v-col>
        <v-card class="mx-auto my-12 pa-5"
                style="width: 90%;overflow-y: scroll; background-color: rgba(255, 255, 255, 0.9); border-radius: 10px;">
          <v-row class="justify-center mt-3">
            <v-col class="d-flex justify-center" cols="5">
              <v-text-field v-model="vorname" label="Vorname" variant="outlined"/>
            </v-col>
            <v-col class="d-flex justify-center" cols="5">
              <v-text-field v-model="name" label="Nachname" variant="outlined"/>
            </v-col>
            <v-col class="d-flex justify-center" cols="4">
              <v-text-field v-model="adresse" label="Adresse" variant="outlined"/>
            </v-col>
            <v-col class="d-flex justify-center" cols="2">
              <v-text-field v-model="plz" label="PLZ" type="number" variant="outlined"/>
            </v-col>
            <v-col class="d-flex justify-center" cols="4">
              <v-text-field v-model="stadt" label="Ort" variant="outlined"/>
            </v-col>
            <v-col :cols="file || imageBase64 ? '5' : '10'">
              <v-file-input
                  v-model="file"
                  accept="image/*"
                  label="Wähle ein Bild aus"
                  @change="handleFileInput"
                  @click:clear="imageBase64 = null"
              ></v-file-input>
            </v-col>
            <v-col v-if="file" class="d-flex justify-center align-center" cols="5">
              <img v-if="imageBase64" :src="imageBase64" alt="Vorschau" height="100"/>
            </v-col>
            <v-col class="d-flex justify-center" cols="5">
              <v-switch
                  v-model="model"
                  :label="model ? 'Männlich' : 'Weiblich'"
                  inset
              ></v-switch>
            </v-col>
            <v-col class="d-flex justify-center center align-center mt-n5" cols="5">
              <v-btn @click="create">
                Speichern
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar
        v-model="snackbar"
        :color="snackbarColor"
        location="top"
        timeout="5000"
    >
      {{ snackbarText }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      tab: null,
      vorname: null,
      name: null,
      adresse: null,
      plz: null,
      stadt: null,
      geschlecht: null,
      model: false,
      file: null,
      imageBase64: '',

      snackbar: false,
      snackbarText: null,
      snackbarColor: null,
    };
  },
  computed: {
    ...mapGetters(['vorsitzende']),
  },
  mounted() {
    this.get();
  },
  methods: {
    activateSnackbar(snackbarText, snackbarColor) {
      this.snackbarText = snackbarText;
      this.snackbarColor = snackbarColor;
      this.snackbar = true;
    },
    async create() {
      try {
        await axios.put('/StellvertretenderVorsitzender/1', {
          id: 1,
          name: this.name,
          vorname: this.vorname,
          adresse: this.adresse,
          stadt: this.stadt,
          bild: this.imageBase64,
          plz: this.plz,
          geschlecht: this.model ? 'm' : 'w'
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

        await this.get();
        this.activateSnackbar('Änderungen erfolgreich gespeichert', 'success');
      } catch (e) {
        alert("Es ist ein Fehler aufgetreten. Versuchen Sie es erneut. Falls das Problem weiterhin besteht, kontaktieren Sie bitte den Administrator.");
      }
    },

    async get() {
      const response = await axios.get('/StellvertretenderVorsitzender/1', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      const resultObject = response.data;

      this.name = resultObject.name;
      this.vorname = resultObject.vorname;
      this.adresse = resultObject.adresse;
      this.stadt = resultObject.stadt;
      this.plz = resultObject.plz;
      this.model = resultObject.geschlecht === 'm';
      this.imageBase64 = resultObject.bild
      this.file = resultObject.bild ? {name: 'Wählen Sie ein Bild aus'} : null;
    },
    handleFileInput() {
      if (this.file) {
        this.convertToBase64(this.file);
      }
    },
    convertToBase64(file) {
      const reader = new FileReader();

      reader.onload = () => {
        this.imageBase64 = reader.result;
      };

      reader.readAsDataURL(file);
    },
  }
};
</script>


<style scoped>
.v-row {
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
}

.container {
  background-color: rgba(255, 255, 255, 0.9);
  border: 2px solid black;
  padding: 20px;
  width: 70%;
  margin: 50px auto;
  position: absolute;
  top: 0px;
  overflow: hidden;
  border-radius: 10px;
}

.image {
  float: left;
  margin-right: 20px;
}

.text {
  text-align: justify;
}

.v-card {
  background-color: rgba(255, 255, 255, 0.9) !important;
}
</style>
