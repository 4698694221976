<template>
  <v-app>
    <v-main >
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),

  created(){
    this.checkMobileView()
    this.resize()
  },
  methods:{
    resize() {
      window.addEventListener('resize', this.checkMobileView);
    },
    checkMobileView() {
      if (window.innerWidth >= 1800) {
        this.$store.state.wide = true;
      } else {
        this.$store.state.wide = false;

      }
      if (window.innerWidth <= 1000) {
        this.$store.state.mobile = true;
      } else {
        this.$store.state.mobile = false;

      }
      if (window.innerWidth <= 1000 && window.innerWidth >= 750) {
        this.$store.state.tablet = true;
      } else {
        this.$store.state.tablet = false;

      }
      if (window.innerWidth <= 1350  && window.innerWidth >= 1000) {
        this.$store.state.tabletHorizontal = true
      } else {
        this.$store.state.tabletHorizontal = false
      }
    }
  }
}
</script>
<style>
.grey{
  background-color: #edecec;
}
</style>
