<template>
  <div v-if="!$store.state.mobile">
    <HeaderComponent></HeaderComponent>
    <div  class=" mt-10 " :class="$store.state.mobile? 'px-4':'px-16'" style="background-color: #ffffff; min-height: 100vh ">
      <v-row>
        <v-col cols="12">

            <div class="col-xs-12 col-lg-9">

              <h4>Impressum</h4>
              <br>
              <p>

                Kai-Uwe Groll <br>
                Zum Leinweber 22<br>
                59505 Bad Sassendorf<br>
                <a href="mailto:seniorenheim-Sonneneck@t-online.de">
                  seniorenheim-Sonneneck@t-online.de
                </a><br><br>

                Kontakt: <br>
                <a href="mailto:info@buergergemeinschaft-bad-sassendorf.de">
                  info@buergergemeinschaft-bad-sassendorf.de
                </a><br><br>
                Die aufgeführten Inhalte werden mit großer Sorgfalt zusammengestellt und geprüft. Die Redaktion
                übernimmt aber keine Gewähr für die Vollständigkeit, Richtigkeit, Aktualität oder Qualität der <br>
                Dokumente. Links zu Webseiten Dritter auf die direkt oder indirekt verwiesen wird, werden als
                Serviceleistung angeboten. Für die Inhalte, zu denen verlinkt wird, sind die Anbieter der jeweiligen
                <br>
                Webseiten selbst verantwortlich. Die Redaktion hat keinen Einfluss auf Inhalt oder Gestaltung der
                verlinkten Seiten. Sie übernimmt für Inhalt und Gestaltung dieser Seiten keine Haftung. Alle Angaben
                <br>
                können ohne vorherige Ankündigung verändert, ergänzt, gelöscht oder eingestellt werden. Alle genannten
                Namen, Bezeichnungen oder Logos sind eingetragene Warenzeichen und Eigentum der jeweiligen
                Rechteinhaber.<br><br>
              </p>
              <br>
              <h4>Haftungsausschuss</h4>
              <p>
                Bei direkten oder indirekten Verweisen auf fremde Internetseiten ("Links"), die außerhalb des Verantwortungsbereiches dieses Internetpotals liegen,  würde eine Haftungsverpflichtung ausschließlich in dem Fall in Kraft treten, in dem die Verantwortlichen dieses Internetportales von den Inhalten Kenntnis haben und es ihnen technisch möglich und zumutbar wäre, die Nutzung im Falle rechtswidriger Inhalte zu verhindern.
                Die Verantwortlichen dieses Internetsportals erklären daher ausdrücklich, dass zum Zeitpunkt der Linksetzung die entsprechenden verlinkten Seiten frei von illegalen Inhalten waren. Die Verantwortlichen dieses Internetsportals haben keinerlei Einfluss auf die aktuelle und zukünftige Gestaltung und auf die Inhalte der gelinkten und/oder verknüpften Seiten. Deshalb distanzieren sich die Verantwortlichen dieses Internetsportals hiermit ausdrücklich von allen Inhalten aller gelinkten und/oder verknüpften Seiten, die nach der Linksetzung verändert wurden.
                Für illegale, fehlerhafte oder unvollständige Inhalte und insbesondere für Schäden, die aus der Nutzung oder Nichtnutzung solcherart dargebotener Informationen entstehen, haftet allein der Anbieter der Seite, auf welche verwiesen wurde, nicht aber derjenige, der über Links auf die jeweilige Veröffentlichung lediglich verweist.
              </p>
              <br>
              <h4>Copyright</h4>
              <p>
                Sämtliche Texte, Bilder und andere auf dem Internetportal der Bürgergemeinschaft Bad Sassendorf veröffentlichten Werke unterliegen - sofern nicht anders gekennzeichnet - dem Schutz des Urheberrechts und anderen Schutzgesetzen. Jede Vervielfältigung, Verbreitung, Speicherung, Übermittlung, Sendung und Wieder- bzw. Weitergabe der Inhalte ist ohne schriftliche Genehmigung der Bürgergemeinschaft Bad Sassendorf ausdrücklich untersagt!              </p>
              <h4>Website created by </h4>
              <a href="https://franke-arts.de" style="color: #2F53A7" target="_blank">Stefan Franke</a>
              <br>
              <a href="https://leandro-graf.de" style="color: #2F53A7" target="_blank">Leandro Graf</a>
              <br>
              <br>


            </div>
        </v-col>
      </v-row>
    </div>
    <FooterComponent></FooterComponent>

  </div>
  <div v-if="$store.state.mobile" style="height: 100vh;">
    <v-layout>

      <v-navigation-drawer
          location="right"
          style="background-image: linear-gradient(to top, #000099 10%, #0909cd 70%);color: white"
          v-model="$store.state.drawer"
          temporary
      >
        <DrawerComponent></DrawerComponent>
      </v-navigation-drawer>
      <v-main style="height: 100%">
        <HeaderComponent></HeaderComponent>
        <div  class=" mt-10 " :class="$store.state.mobile? 'px-4':'px-16'" style="background-color: #ffffff; min-height: 100vh ">
          <v-row>
            <v-col cols="12">

              <div class="col-xs-12 col-lg-9">

                <h4>Impressum</h4>
                <br>
                <p>

                  Kai-Uwe Groll <br>
                  Zum Leinweber 22<br>
                  59505 Bad Sassendorf<br>
                  <a href="mailto:seniorenheim-Sonneneck@t-online.de">
                    seniorenheim-Sonneneck@t-online.de
                  </a><br><br>

                  Kontakt: <br>
                  <a href="mailto:info@buergergemeinschaft-bad-sassendorf.de">
                    info@buergergemeinschaft-bad-sassendorf.de
                  </a><br><br>
                  Die aufgeführten Inhalte werden mit großer Sorgfalt zusammengestellt und geprüft. Die Redaktion
                  übernimmt aber keine Gewähr für die Vollständigkeit, Richtigkeit, Aktualität oder Qualität der <br>
                  Dokumente. Links zu Webseiten Dritter auf die direkt oder indirekt verwiesen wird, werden als
                  Serviceleistung angeboten. Für die Inhalte, zu denen verlinkt wird, sind die Anbieter der jeweiligen
                  <br>
                  Webseiten selbst verantwortlich. Die Redaktion hat keinen Einfluss auf Inhalt oder Gestaltung der
                  verlinkten Seiten. Sie übernimmt für Inhalt und Gestaltung dieser Seiten keine Haftung. Alle Angaben
                  <br>
                  können ohne vorherige Ankündigung verändert, ergänzt, gelöscht oder eingestellt werden. Alle genannten
                  Namen, Bezeichnungen oder Logos sind eingetragene Warenzeichen und Eigentum der jeweiligen
                  Rechteinhaber.<br><br>
                </p>
                <br>
                <h4>Haftungsausschuss</h4>
                <p>
                  Bei direkten oder indirekten Verweisen auf fremde Internetseiten ("Links"), die außerhalb des Verantwortungsbereiches dieses Internetpotals liegen,  würde eine Haftungsverpflichtung ausschließlich in dem Fall in Kraft treten, in dem die Verantwortlichen dieses Internetportales von den Inhalten Kenntnis haben und es ihnen technisch möglich und zumutbar wäre, die Nutzung im Falle rechtswidriger Inhalte zu verhindern.
                  Die Verantwortlichen dieses Internetsportals erklären daher ausdrücklich, dass zum Zeitpunkt der Linksetzung die entsprechenden verlinkten Seiten frei von illegalen Inhalten waren. Die Verantwortlichen dieses Internetsportals haben keinerlei Einfluss auf die aktuelle und zukünftige Gestaltung und auf die Inhalte der gelinkten und/oder verknüpften Seiten. Deshalb distanzieren sich die Verantwortlichen dieses Internetsportals hiermit ausdrücklich von allen Inhalten aller gelinkten und/oder verknüpften Seiten, die nach der Linksetzung verändert wurden.
                  Für illegale, fehlerhafte oder unvollständige Inhalte und insbesondere für Schäden, die aus der Nutzung oder Nichtnutzung solcherart dargebotener Informationen entstehen, haftet allein der Anbieter der Seite, auf welche verwiesen wurde, nicht aber derjenige, der über Links auf die jeweilige Veröffentlichung lediglich verweist.
                </p>
                <br>
                <h4>Copyright</h4>
                <p>
                  Sämtliche Texte, Bilder und andere auf dem Internetportal der Bürgergemeinschaft Bad Sassendorf veröffentlichten Werke unterliegen - sofern nicht anders gekennzeichnet - dem Schutz des Urheberrechts und anderen Schutzgesetzen. Jede Vervielfältigung, Verbreitung, Speicherung, Übermittlung, Sendung und Wieder- bzw. Weitergabe der Inhalte ist ohne schriftliche Genehmigung der Bürgergemeinschaft Bad Sassendorf ausdrücklich untersagt!              </p>
                <h4>Website created by </h4>
                <a href="https://franke-arts.de" style="color: #2F53A7" target="_blank">Stefan Franke</a>
                <br>
                <a href="https://leandro-graf.de" style="color: #2F53A7" target="_blank">Leandro Graf</a>
                <br>
                <br>


              </div>
            </v-col>
          </v-row>
        </div>

        <FooterComponent></FooterComponent>
      </v-main>
    </v-layout>


  </div>
</template>
<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import DrawerComponent from "@/components/DrawerComponent.vue";

export default {
  name: "KontaktView",
  components: {DrawerComponent, FooterComponent, HeaderComponent}
}
</script>

<style scoped>

</style>
