<script>
export default {
  name: "drawerComponent"
}
</script>

<template>

  <v-row class="mx-0 mt-5" style="height: 180px; width: 100%">

    <v-col @click="$store.state.drawer= false" class="d-flex align-end " cols="12" >
      <router-link active-class="active-link" aria-label="ueberuns" class="nav-link" title="ueberuns"
                   to="/ueberuns">
        <h4>Verein</h4>
      </router-link>
    </v-col>
    <v-divider></v-divider>
    <v-col @click="$store.state.drawer= false" class="d-flex align-end " cols="12" >
      <router-link active-class="active-link" aria-label="Fraktion" class="nav-link" title="Fraktion"
                   to="/fraktion">
        <h4 >Fraktion</h4>
      </router-link>
    </v-col>
    <v-divider></v-divider>

    <v-col @click="$store.state.drawer= false" class="d-flex align-end " cols="12" >
      <router-link active-class="active-link" aria-label="Aktuelles" class="nav-link" title="Aktuelles"
                   to="/aktuelles">
        <h4>Aktuelles</h4>
      </router-link>
    </v-col>
    <v-divider></v-divider>

    <v-col @click="$store.state.drawer= false" class="d-flex align-end " cols="12" >
      <router-link active-class="active-link" aria-label="Zum Kalender" class="nav-link" title="Zum Kalender"
                   to="/kalender">
        <h4>Termine</h4>
      </router-link>
    </v-col>
    <v-divider></v-divider>

    <v-col @click="$store.state.drawer= false" class="d-flex align-end " cols="12" >
      <router-link active-class="active-link" aria-label="Kontakt" class="nav-link" title="Kontakt" to="/kontakt">
        <h4>Kontakt</h4>
      </router-link>
    </v-col>
    <v-divider></v-divider>

    <v-col class="d-flex align-end " cols="12" >
      <a aria-label="Zum Portal" class="nav-link" href="https://sessionnet.owl-it.de/badsassendorf/bi/info.asp"
         target="_blank" title="Zum Portal">
        <h4 >Bürgerinfoportal</h4>
      </a>
    </v-col>
    <v-divider></v-divider>

  </v-row>

</template>

<style scoped>

.nav-link {
  text-decoration: none;
  color: inherit;
}
</style>