<template>
  <v-form  @submit.prevent="login">
    <v-card class="card">
      <v-card-title class="text-center">
        <span class="text-h5 text-white ">Einloggen</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row class="d-flex justify-center" style="width: 100%">
            <v-col
                cols="12">
              <v-text-field v-model="email"
                            color="white"
                            label="Email"
                            required
                            style="color: white"
                            variant="underlined"
              ></v-text-field>
            </v-col>
            <v-col
                cols="12">
              <v-text-field
                  v-model="password"
                  :append-icon="show ?'mdi-eye':'mdi-eye-off'"
                  :type="show ?'text':'password'"
                  color="white"
                  label="Passwort"
                  name="password"
                  persistent-hint
                  required
                  style="color: white"
                  variant="underlined"
                  @click:append="show=!show">
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-alert v-if="error === 'AxiosError: Request failed with status code 401'"
                 class="text-center text-black" type="error">
          Benutzername oder Passwort ist falsch.
        </v-alert>

        <v-alert v-if="error !== 'AxiosError: Request failed with status code 401' && error != null"
                 class="text-center text-black" type="error">
          Einloggen ist momentan nicht möglich. Bitte wenden Sie sich an den Administrator.
        </v-alert>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-row>
          <v-col class="d-flex justify-end" cols="6">
            <v-btn
                color="white"
                type="submit"
                variant="outlined">
              Einloggen
            </v-btn>
          </v-col>
          <v-col class="d-flex justify-start" cols="6">
            <v-btn
                color="white" variant="outlined" @click="$router.push('/')">
              Abbrechen
            </v-btn>
          </v-col>
          <v-col class="d-flex justify-center mt-n5" cols="12">
            <v-btn v-if="anmeldeversuch >= 3" variant="text" @click="forgetPassword">
              passwort vergessen
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import {mapGetters} from "vuex";
import axios from "axios";

export default {
  name: "LoginComponent",
  data: () => ({
    email: null,
    password: '',
    show: false,
    anmeldeversuch: 0
  }),

  components: {},
  computed: {
    ...mapGetters(['user'])
  },
  methods: {
    async login() {
      try {
        const response = await axios.post('/login', {
          email: this.email,
          password: this.password
        });
        await localStorage.setItem('token', response.data.token);
        this.$store.dispatch('setUser', response.data.user);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          alert('Benutzername oder Passwort ist falsch.');
          this.anmeldeversuch++
        } else {
          alert('Einloggen ist momentan nicht möglich. Bitte wenden Sie sich an den Administrator.');
        }
        console.log(error);
      }
    },
    forgetPassword() {
      if (this.anmeldeversuch >= 3) {
        if (this.email === null || this.email === '') {
          alert("Bitte geben Sie Ihre Email-Adresse ein.");
        } else {
          // Post-Request mit Axios an den /password/reset-Endpunkt
          axios.post('/password/reset', {email: this.email})
              .then(() => {
                alert("Sofern Ihre Email übereinstimmt, bekommen Sie ein neues Passwort per Email zugesendet.");
              })
              .catch(error => {
                console.error(error);
                alert("Es gab ein Problem beim Zurücksetzen des Passworts. Bitte versuchen Sie es später erneut.");
              });
        }
      }
    }
  },

}
</script>

<style>
.card {
  background-color: rgba(255, 255, 255, 0.10);
  border-top: 1px solid rgba(255, 255, 255, 0.75);
  border-left: 1px solid rgba(255, 255, 255, 0.75);
  border-radius: 30px;
  backdrop-filter: blur(10px);
  box-shadow: 5px 5px 10px #2c2a2a;
  height: 350px;
  width: 600px;
}
</style>