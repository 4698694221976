<template>
  <div v-if="!$store.state.mobile" style="height: 100%">
    <HeaderComponent></HeaderComponent>
    <v-row class="  justify-center" style="width: 100%;">
      <v-col v-if="!$store.state.mobile" class="d-flex justify-center" cols="8">
        <iframe
            frameborder="0"
            height="600" scrolling="no"
            src="https://calendar.google.com/calendar/embed?height=600&wkst=2&ctz=Europe%2FBerlin&bgcolor=%23ffffff&showTitle=0&showPrint=0&showTabs=0&showCalendars=0&showTz=0&src=YmdiYWRzYXNzZW5kb3JmQGdtYWlsLmNvbQ&color=%23039BE5"
            style="border:solid 1px " width="1200"></iframe>
      </v-col>

    </v-row>
    <div class="mt-10">
      <FooterComponent></FooterComponent>
    </div>
  </div>
  <div v-if="$store.state.mobile" style="height: 100vh;">
    <v-layout>

      <v-navigation-drawer
          location="right"
          style="background-image: linear-gradient(to top, #000099 10%, #0909cd 70%);color: white"
          v-model="$store.state.drawer"
          temporary
      >
        <DrawerComponent></DrawerComponent>
      </v-navigation-drawer>
      <v-main style="height: 100%">
        <HeaderComponent></HeaderComponent>
        <v-row class=" pb-10 justify-center" style="width: 100%;">
          <v-col v-if="$store.state.mobile" class="d-flex justify-center" cols="8">
            <iframe
                height="600" scrolling="no"
                src="https://calendar.google.com/calendar/embed?height=600&wkst=2&ctz=Europe%2FBerlin&bgcolor=%23ffffff&showTitle=0&showPrint=0&showTabs=0&showCalendars=0&showTz=0&title&mode=AGENDA&showDate=0&showNav=0&src=YmdiYWRzYXNzZW5kb3JmQGdtYWlsLmNvbQ&color=%23039BE5"
                style="border-width:0; border-radius: 10px  " width="800"></iframe>
          </v-col>
        </v-row>
        <FooterComponent></FooterComponent>
      </v-main>
    </v-layout>


  </div>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import DrawerComponent from "@/components/DrawerComponent.vue";

export default {
  components: {
    DrawerComponent,
    FooterComponent,
    HeaderComponent
  },
};
</script>


<style scoped>
.v-row {
  width: 100%;
  margin: 0;
}
</style>
