<template>
  <div v-if="!$store.state.mobile" >
    <HeaderComponent></HeaderComponent>
    <div class="white py-4">
      <v-row style="width: 100%" class="ma-0 mt-10 justify-center">
        <v-col cols="9" class="d-flex ml-16">
          <div style="height: 100% ;width: 12px; background-color: #01009a">

          </div>
          <h2 class="ml-2">
            Vorsitzende
          </h2>
        </v-col>
        <v-col cols="3" class="mr-5">
          <div  style="width: 100%; height: 650px; padding:20px; background-image: linear-gradient(to top, #000099 40%, #0909cd 70%); box-shadow: 2px 2px 5px rgba(0,0,0,0.81)">
            <v-img v-if="loading" style="height: 450px"  :src="vorsitzender?.bild"></v-img>
            <h2 class="text-white text-center mt-4">Vorsitzender</h2>
            <h3 class="text-white text-center mt-1">{{vorsitzender?.vorname}} {{vorsitzender?.name}}</h3>
            <p class="text-white text-center mt-1">
              <a class="text-white" href="mailto:fraktionsVorsitzender@bg-badsassendorf.de">
                fraktionsVorsitzender@bg-badsassendorf.de
              </a>
            </p>
          </div>
        </v-col>
        <v-col cols="2" class="mr-5"></v-col>
        <v-col cols="3">
          <div style="width: 100%; height: 650px; padding:20px; background-image: linear-gradient(to top, #000099 40%, #0909cd 70%); box-shadow: 2px 2px 5px rgba(0,0,0,0.81)">
            <v-img v-if="loading" style="height: 450px"  :src="stellvVorsitzender?.bild"></v-img>
            <h2 class="text-white text-center mt-4">Stellvertretender Vorsitzender</h2>
            <h3 class="text-white text-center mt-1">{{stellvVorsitzender?.vorname}} {{stellvVorsitzender?.name}}</h3>
            <p class="text-white text-center mt-1">
              <a class="text-white" href="mailto:stellvFraktionsVorsitzender@bg-badsassendorf.de">
                stellvFraktionsVorsitzender@bg-badsassendorf.de
              </a>
            </p>
          </div>
        </v-col>


      </v-row>
      <v-row  style="width: 100%; background-color: #f1f1f1" class="ma-0 pt-6 justify-center">
        <v-col cols="9" class="d-flex ml-16">
          <div style="height: 100% ;width: 12px; background-color: #01009a">

          </div>
          <h2 class="ml-2">
            Mitglieder
          </h2>
        </v-col>

        <v-col v-for=" mitglied in mitglieder" class=""  :key="mitglied" cols="3">
          <div style="width: 100%; height: 650px; padding:20px; background-image: linear-gradient(to top, #000099 40%, #0909cd 70%); box-shadow: 2px 2px 5px rgba(0,0,0,0.81)">
            <v-img v-if="loading" style="height: 450px"  :src="mitglied?.bild"></v-img>
            <h3 class="text-white text-center mt-8">{{mitglied?.vorname}} {{mitglied?.name}}</h3>

          </div>
        </v-col>
      </v-row>
    </div>
    <FooterComponent></FooterComponent>

  </div >
  <div v-if="$store.state.mobile" style="height: 100vh;">
    <v-layout>

      <v-navigation-drawer
          location="right"
          style="background-image: linear-gradient(to top, #000099 10%, #0909cd 70%);color: white"
          v-model="$store.state.drawer"
          temporary
      >
        <DrawerComponent></DrawerComponent>
      </v-navigation-drawer>
      <v-main style="height: 100%">
        <HeaderComponent></HeaderComponent>
        <v-row style="width: 100%" class="ma-0 mt-10 pb-10 justify-center">
          <v-col cols="11" class="d-flex">
            <div style="height: 100% ;width: 12px; background-color: #01009a">

            </div>
            <h2 class="ml-2">
              Vorsitzende
            </h2>
          </v-col>
          <v-col cols="11" >
            <div style="width: 100%; height: 650px; padding:20px; background-image: linear-gradient(to top, #000099 40%, #0909cd 70%)">
              <v-img v-if="loading" style="height: 450px"  :src="vorsitzender?.bild"></v-img>
              <h2 class="text-white text-center mt-4">Vorsitzender</h2>
              <h3 class="text-white text-center mt-1">{{vorsitzender?.vorname}} {{vorsitzender?.name}}</h3>
              <p class="text-white text-center mt-1">
                <a class="text-white" href="mailto:fraktionsVorsitzender@bg-badsassendorf.de">
                  fraktionsVorsitzender@bg-badsassendorf.de
                </a>
              </p>
            </div>
          </v-col>

          <v-col cols="11">
            <div style="width: 100%; height: 650px; padding:20px; background-image: linear-gradient(to top, #000099 40%, #0909cd 70%)">
              <v-img v-if="loading" style="height: 450px"  :src="stellvVorsitzender?.bild"></v-img>
              <h2 class="text-white text-center mt-4">Stellvertretender Vorsitzender</h2>
              <h3 class="text-white text-center mt-1">{{stellvVorsitzender?.vorname}} {{stellvVorsitzender?.name}}</h3>
              <p class="text-white text-center mt-1">
                <a class="text-white" href="mailto:stellvFraktionsVorsitzender@bg-badsassendorf.de">
                  stellvFraktionsVorsitzender@bg-badsassendorf.de
                </a>
              </p>
            </div>
          </v-col>


        </v-row>
        <v-row  style="width: 100%; background-color: #f1f1f1" class="ma-0 pt-6 pb-10 justify-center">
          <v-col cols="11" class="pl-0 d-flex">
            <div style="height: 100% ;width: 12px; background-color: #01009a">

            </div>
            <h2 class="ml-2">
              Mitglieder
            </h2>
          </v-col>

          <v-col v-for=" mitglied in mitglieder" class="px-0"  :key="mitglied" cols="11">
            <div style="width: 100%; height: 650px; padding:20px; background-image: linear-gradient(to top, #000099 40%, #0909cd 70%)">
              <v-img v-if="loading" style="height: 450px"  :src="mitglied?.bild"></v-img>
              <h3 class="text-white text-center mt-8">{{mitglied?.vorname}} {{mitglied?.name}}</h3>

            </div>
          </v-col>
        </v-row>
        <FooterComponent></FooterComponent>
      </v-main>
    </v-layout>


  </div>
</template>
<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import axios from "axios";
import DrawerComponent from "@/components/DrawerComponent.vue";

export default {
  data(){
    return{
      loading: false,
      vorsitzender: null,
      stellvVorsitzender: null,
      mitglieder: null,

    }
  },
  methods: {
    async getVorsitzender() {
      let response = await axios.get('fraktionsvorsitzender/1')
      this.vorsitzender = response.data
      console.log(response.data)
    },
    async getStellvVorsitzender() {
      let response = await axios.get('stellvertretenderFraktionsvorsitzender/1')
      this.stellvVorsitzender = response.data
      console.log(response.data)
    },
    async getMitglieder() {
      let response = await axios.get('mitgliedImGemeinderat')
      this.mitglieder = response.data
      console.log(response.data)
    }
  },
  async mounted() {
    this.loading = false
    await this.getVorsitzender()
    await this.getStellvVorsitzender()
    await this.getMitglieder()
    this.loading = true

  },
  components: {DrawerComponent, FooterComponent, HeaderComponent},

}
</script>

<style scoped>

</style>
