// src/axios.js
import axios from 'axios';

axios.defaults.baseURL = 'https://bg-badsassendorf.de:8443/auth';
axios.defaults.headers.common['Content-Type'] = 'application/json';

/*const token = localStorage.getItem('token');
if (token) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}*/

export default axios;
