<template>
  <div v-if="!$store.state.mobile">
    <HeaderComponent></HeaderComponent>
    <div style="height: 500px;" class="mt-3">
      <v-row style="width: 100%;" class="justify-center mx-0">
        <v-col cols="10">
          <v-text-field
              v-model="search"
              label="Suche..."
              prepend-icon="mdi-magnify"
              class="mb-4"
              density="comfortable"
              variant="outlined"
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- Suchfeld hinzufügen -->

      <v-row v-for="(bericht, index) in filteredAktuelles" :key="bericht" style="width: 100%; height: 100%" :class="{ grey: index % 2 === 1 }" class="mx-0 justify-center align-center">
        <v-col cols="5">
          <v-img cover style="height: 450px; box-shadow: 2px 2px 5px rgba(0,0,0,0.84)" :src="bericht.bild"></v-img>
        </v-col>
        <v-col cols="5">
          <h3>{{ bericht.ueberschrift }}</h3>
          <h4 style="color: #7c7c7c">{{ bericht.unterueberschrift }}</h4>
          <br><br>
          <p style="font-size: 16px" v-for="absatz in bericht.absaetze" :key="absatz" class="font-weight-light">
            {{ absatz.inhalt }}
            <br>
          </p>
          <br><br>
          <p style="font-size: 16px; color: #7c7c7c" class="font-weight-light">
            <b>{{ bericht.autor }}</b>
          </p>
          <br>
          <p style="font-size: 16px; color: #7c7c7c" class="font-weight-light">
            <b>{{ bericht.datum }}</b>
          </p>
        </v-col>
      </v-row>
      <FooterComponent></FooterComponent>
    </div>
  </div>

  <div v-if="$store.state.mobile" style="height: 100vh;">
    <v-layout>
      <v-navigation-drawer
          location="right"
          style="background-image: linear-gradient(to top, #000099 10%, #0909cd 70%); color: white"
          v-model="$store.state.drawer"
          temporary
      >
        <DrawerComponent></DrawerComponent>
      </v-navigation-drawer>
      <v-main style="height: 100%">
        <HeaderComponent></HeaderComponent>
        <!-- Suchfeld hinzufügen -->
        <v-text-field
            v-model="search"
            label="Suche"
            prepend-icon="mdi-magnify"
            class="mb-4"
        ></v-text-field>

        <v-row v-for="bericht in filteredAktuelles" :key="bericht" style="width: 100%; height: 100%" class="mx-0 pb-10 justify-center align-center">
          <v-col cols="11">
            <v-img cover style="height: 450px; box-shadow: 2px 2px 5px rgba(0,0,0,0.82)" :src="bericht.bild"></v-img>
          </v-col>
          <v-col cols="11">
            <h3>{{ bericht.ueberschrift }}</h3>
            <h4 style="color: #7c7c7c">{{ bericht.unterueberschrift }}</h4>
            <br><br>
            <p style="font-size: 16px" v-for="absatz in bericht.absaetze" :key="absatz" class="font-weight-light">
              {{ absatz.inhalt }}
              <br>
            </p>
            <br><br>
            <p style="font-size: 16px; color: #7c7c7c" class="font-weight-light">
              <b>{{ bericht.autor }}</b>
            </p>
            <br>
            <p style="font-size: 16px; color: #7c7c7c" class="font-weight-light">
              <b>{{ bericht.datum }}</b>
            </p>
          </v-col>
        </v-row>
        <FooterComponent></FooterComponent>
      </v-main>
    </v-layout>
  </div>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import axios from "axios";
import DrawerComponent from "@/components/DrawerComponent.vue";

export default {
  name: "KontaktView",
  data() {
    return {
      aktuelles: [],
      search: "", // Suchbegriff hinzufügen
    };
  },
  components: { DrawerComponent, FooterComponent, HeaderComponent },
  mounted() {
    this.getAktuelles();
  },
  computed: {
    // Filterfunktion für die Suche
    filteredAktuelles() {
      if (!this.search) {
        return this.aktuelles;
      }
      return this.aktuelles.filter((bericht) => {
        const searchLower = this.search.toLowerCase();
        return (
            bericht.ueberschrift.toLowerCase().includes(searchLower) ||
            bericht.unterueberschrift.toLowerCase().includes(searchLower) ||
            bericht.absaetze.some((absatz) =>
                absatz.inhalt.toLowerCase().includes(searchLower)
            )
        );
      });
    },
  },
  methods: {
    async getAktuelles() {
      try {
        let response = await axios.get("/aktuelles");
        this.aktuelles = response.data;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped>
</style>
