import {createStore} from 'vuex'

export default createStore({
    state: {
        user: false,
        vorsitzende: [],
        fraktionsvorsitzende: [],
        stellvertretenderFraktionsvorsitzender: [],
        mitgliedImGemeinderat: [],
        aktuelles: [],
        schatzmeister: [],
        schriftfuehrer: [],
        stellvertretenderVorsitzender: [],
        wide: false,
        tablet: false,
        tabletHorizontal: false,
        mobile: false,
        drawer: false,
    },
    getters: {
        user: (state) => state.user,
        vorsitzende: (state) => state.vorsitzende,
        fraktionsvorsitzende: (state) => state.fraktionsvorsitzende,
        stellvertretenderFraktionsvorsitzender: (state) => state.stellvertretenderFraktionsvorsitzender,
        mitgliedImGemeinderat: (state) => state.mitgliedImGemeinderat,
        aktuelles: (state) => state.aktuelles,
        schatzmeister: (state) => state.schatzmeister,
        schriftfuehrer: (state) => state.schriftfuehrer,
        stellvertretenderVorsitzender: (state) => state.stellvertretenderVorsitzender,
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
        setVorsitzende(state, vorsitzende) {
            state.vorsitzende = vorsitzende;
        },
        setFraktionsvorsitzende(state, fraktionsvorsitzende) {
            state.fraktionsvorsitzende = fraktionsvorsitzende;
        },
        setStellvertretenderFraktionsvorsitzender(state, stellvertretenderFraktionsvorsitzender) {
            state.stellvertretenderFraktionsvorsitzender = stellvertretenderFraktionsvorsitzender;
        },
        setMitgliedImGemeinderat(state, mitgliedImGemeinderat) {
            state.mitgliedImGemeinderat = mitgliedImGemeinderat;
        },
        setAktuelles(state, aktuelles) {
            state.aktuelles = aktuelles;
        },
        setSchatzmeister(state, schatzmeister) {
            state.schatzmeister = schatzmeister;
        },
        setSchriftfuehrer(state, schriftfuehrer) {
            state.schriftfuehrer = schriftfuehrer;
        },
        setStellvertretenderVorsitzender(state, stellvertretenderVorsitzender) {
            state.stellvertretenderVorsitzender = stellvertretenderVorsitzender;
        },
    },
    actions: {
        setUser(context, user) {
            context.commit('setUser', user);
        },
        setVorsitzende(context, vorsitzende) {
            context.commit('setVorsitzende', vorsitzende);
        },
        setFraktionsvorsitzende(context, fraktionsvorsitzende) {
            context.commit('setFraktionsvorsitzende', fraktionsvorsitzende);
        },
        setStellvertretenderFraktionsvorsitzender(context, stellvertretenderFraktionsvorsitzender) {
            context.commit('setStellvertretenderFraktionsvorsitzender', stellvertretenderFraktionsvorsitzender);
        },
        setMitgliedImGemeinderat(context, mitgliedImGemeinderat) {
            context.commit('setMitgliedImGemeinderat', mitgliedImGemeinderat);
        },
        setAktuelles(context, aktuelles) {
            context.commit('setAktuelles', aktuelles);
        },
        setSchatzmeister(context, schatzmeister) {
            context.commit('setSchatzmeister', schatzmeister);
        },
        setSchriftfuehrer(context, schriftfuehrer) {
            context.commit('setSchriftfuehrer', schriftfuehrer);
        },
        setStellvertretenderVorsitzender(context, stellvertretenderVorsitzender) {
            context.commit('setStellvertretenderVorsitzender', stellvertretenderVorsitzender);
        },
    },
    modules: {}
});
