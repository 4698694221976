<template>
  <div>
    <v-tabs v-model="tab" align-tabs="center" class="mt-3 text-white" color="black"
            style="height: 100%">
      <v-tab :value="0" style="color: white">Erstellen</v-tab>
      <v-tab :value="1" style="color: white">Löschen</v-tab>
    </v-tabs>

    <v-window v-model="tab">
      <v-window-item value="0">
        <v-container>
          <v-row style="height: 550px">
            <v-col cols="12">
              <v-card class="mx-auto my-12 pa-5"
                      style="width: 90%; background-color: rgba(255, 255, 255, 0.9); border-radius: 10px;">
                <v-row class="justify-center mt-3">
                  <v-col class="d-flex justify-center" cols="5">
                    <v-text-field v-model="vorname" label="Vorname" variant="outlined"/>
                  </v-col>
                  <v-col class="d-flex justify-center" cols="5">
                    <v-text-field v-model="nachname" label="Nachname" variant="outlined"/>
                  </v-col>
                  <v-col class="d-flex justify-center" cols="4">
                    <v-text-field v-model="adresse" label="Adresse" variant="outlined"/>
                  </v-col>
                  <v-col class="d-flex justify-center" cols="2">
                    <v-text-field v-model="plz" label="PLZ" type="number" variant="outlined"/>
                  </v-col>
                  <v-col class="d-flex justify-center" cols="4">
                    <v-text-field v-model="ort" label="Ort" variant="outlined"/>
                  </v-col>
                  <v-col :cols="file ? '5' : '10'">
                    <v-file-input
                        v-model="file"
                        accept="image/*"
                        label="Wähle ein Bild aus"
                        @change="handleFileInput"
                    ></v-file-input>
                  </v-col>
                  <v-col v-if="file" class="d-flex justify-center align-center" cols="5">
                    <img v-if="imageBase64" :src="imageBase64" alt="Vorschau" height="100"/>
                  </v-col>
                  <v-col class="d-flex justify-center" cols="5">
                    <v-switch
                        v-model="model"
                        :label="model ? 'Männlich' : 'Weiblich'"
                        inset
                    ></v-switch>
                  </v-col>
                  <v-col class="d-flex justify-center center align-center mt-n5" cols="5">
                    <v-btn @click="create">
                      Speichern
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-window-item>

      <v-window-item value="1">
        <v-row class="justify-center mt-3" style="width: 100%;">
          <v-col cols="12">
            <v-data-table-virtual :items="mitgliedImGemeinderat" fixed-header height="500" items-per-page="7">
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.id }}</td>
                  <td>{{ item.vorname }}</td>
                  <td>{{ item.name }}</td>
                  <td>
                    <v-img :src="item.bild"/>
                  </td>
                  <td>{{ item.adresse }}</td>
                  <td>{{ item.plz }}</td>
                  <td>{{ item.stadt }}</td>
                  <td>{{ item.geschlecht === 'm' ? 'Männlich' : 'Weiblich' }}</td>
                  <td>
                    <v-icon color="red" style="font-size: 30px" @click="deleteMethod(item)">mdi-delete</v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table-virtual>
          </v-col>
        </v-row>
      </v-window-item>
    </v-window>
  </div>
</template>

<script>
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      tab: 0,
      vorname: null,
      nachname: null,
      adresse: null,
      plz: null,
      ort: null,
      geschlecht: null,
      model: false,
      file: null,
      imageBase64: '',
    };
  },
  computed: {
    ...mapGetters(['mitgliedImGemeinderat']),
  },
  mounted() {
    this.get()
  },
  methods: {

    async create() {
      try {
        await axios.post('/mitgliedImGemeinderat', {
          name: this.nachname,
          vorname: this.vorname,
          adresse: this.adresse,
          stadt: this.ort,
          plz: this.plz,
          bild: this.file ? this.imageBase64 : null,
          geschlecht: this.model ? 'm' : 'w'
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        this.tab = 1;

        await this.get();
        this.clear();
      } catch (e) {
        alert("Es ist ein Fehler aufgetreten. Versuchen Sie es erneut. Falls das Problem weiterhin besteht, kontaktieren Sie bitte den Administrator.");
      }
    },
    clear() {
      this.vorname = '';
      this.nachname = '';
      this.adresse = '';
      this.plz = '';
      this.ort = '';
      this.model = false;
      this.imageBase64 = null
      this.file = null
    },
    async deleteMethod(item) {
      try {
        await axios.delete('/mitgliedImGemeinderat/' + item.id, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        await this.get();
      } catch (e) {
        alert("Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut. Falls das Problem weiterhin besteht, kontaktieren Sie bitte den Administrator.");
      }
    },
    async get() {
      const response = await axios.get('/mitgliedImGemeinderat', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      this.$store.state.mitgliedImGemeinderat = response.data;
    },
    handleFileInput() {
      if (this.file) {
        this.convertToBase64(this.file);
      }
    },
    convertToBase64(file) {
      const reader = new FileReader();

      reader.onload = () => {
        this.imageBase64 = reader.result;
      };

      reader.readAsDataURL(file);
    },
  }
};
</script>

<style scoped>
.v-row {
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
}

.container {
  background-color: rgba(255, 255, 255, 0.9);
  border: 2px solid black;
  padding: 20px;
  width: 70%;
  margin: 50px auto;
  position: absolute;
  top: 0px;
  overflow: hidden;
  border-radius: 10px;
}

.image {
  float: left;
  margin-right: 20px;
}

.text {
  text-align: justify;
}

.v-card {
  background-color: rgba(255, 255, 255, 0.9) !important;
}

</style>
