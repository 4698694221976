<template>
  <v-row v-if="!$store.state.mobile" class="mx-0 align-end justify-center" style="height: 230px; width: 100%">
    <v-col class="d-flex  align-end mt-1" cols="3" style="max-width: 220px; height: 220px">
      <router-link aria-label="BG-Badsassendorf" title="BG-Badsassendorf" to="/">
        <v-img alt="BG-Badsassendorf" src="../assets/Logo.svg"
               style="width: 200px; height: 200px; box-shadow: 2px 2px 5px black">
        </v-img>
      </router-link>
    </v-col>
    <v-col cols="6">
      <v-row class="mx-0 mt-1" style="height: 180px; width: 100%">

        <v-col class="d-flex align-end justify-center" cols="2" lg="2" md="2" sm="2">
          <v-menu>
            <template v-slot:activator="{ props }">

              <div :class="isActiveVerein || isActiveFraktion  ? 'active-link':''" aria-label="Über Uns" class="nav-link"
                   style="cursor: pointer" title="Über Uns" v-bind="props">
                <h4 style="font-size: 1vw">ÜBER UNS</h4>
              </div>
            </template>
            <v-list class="py-0">
              <router-link aria-label="Über uns" class="nav-link" title="Über uns" to="/ueberuns">
                <v-list-item class="py-0" style="max-height: 20px !important; cursor: pointer">
                  <v-list-item-title class="py-0" style="max-height: 20px !important;">
                    <p>Verein</p>
                  </v-list-item-title>
                </v-list-item>
              </router-link>
              <v-divider></v-divider>
              <router-link aria-label="Über uns" class="nav-link" title="Über uns" to="/fraktion">
                <v-list-item>
                  <v-list-item-title>
                    <p>Fraktion</p>
                  </v-list-item-title>
                </v-list-item>
              </router-link>
            </v-list>
          </v-menu>

        </v-col>
        <v-col class="d-flex align-end justify-center" cols="2" lg="2" md="2" sm="2">
          <router-link active-class="active-link" aria-label="Aktuelles" class="nav-link" title="Aktuelles"
                       to="/aktuelles">
            <h4 style="font-size: 1vw">AKTUELLES</h4>
          </router-link>
        </v-col>
        <v-col class="d-flex align-end justify-center" cols="2" lg="2" md="2" sm="2">
          <router-link active-class="active-link" aria-label="Zum Kalender" class="nav-link" title="Zum Kalender"
                       to="/kalender">
            <h4 style="font-size: 1vw">TERMINE</h4>
          </router-link>
        </v-col>
        <v-col class="d-flex align-end justify-center" cols="2" lg="2" md="2" sm="2">
          <router-link active-class="active-link" aria-label="Kontakt" class="nav-link" title="Kontakt" to="/kontakt">
            <h4 style="font-size: 1vw">KONTAKT</h4>
          </router-link>
        </v-col>
        <v-col class="d-flex align-end justify-center" cols="3" lg="3" md="3" sm="3">
          <a aria-label="Zum Portal" class="nav-link" href="https://sessionnet.owl-it.de/badsassendorf/bi/info.asp"
             target="_blank" title="Zum Portal">
            <h4 style="font-size: 1vw">BÜRGERINFOPORTAL</h4>
          </a>
        </v-col>
      </v-row>
    </v-col>
  </v-row>

  <v-row v-if="$store.state.mobile" class="mx-0 mt-3  justify-center" style="height: 230px; width: 100%">
    <v-col class="d-flex justify-center " cols="8" style="max-width: 220px; height: 220px">
      <router-link aria-label="BG-Badsassendorf" title="BG-Badsassendorf" to="/">
        <v-img alt="BG-Badsassendorf" src="../assets/Logo.svg"
               style="width: 160px; height: 160px; box-shadow: 2px 2px 5px black">
        </v-img>
      </router-link>
    </v-col>
    <v-col class="d-flex justify-end" cols="4">
      <Icon icon="solar:hamburger-menu-linear" style="font-size: 50px"
            @click.stop="$store.state.drawer = !$store.state.drawer"/>
    </v-col>
  </v-row>

</template>

<script>

import {Icon} from '@iconify/vue';

export default {
  name: "HeaderComponent",
  data() {
    return {
      drawer: false
    }
  },
  methods: {},
  components: {
    Icon
  },
  computed: {
    isActiveVerein() {
      // Check if the current route path is '/ueberuns'
      return this.$route.path === '/ueberuns';
    },
    isActiveFraktion() {
      // Check if the current route path is '/ueberuns'
      return this.$route.path === '/fraktion';
    }
  }
}
</script>


<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

.nav-link {
  text-decoration: none;
  color: inherit;
}

.active-link h4 {
  color: #01009a;
  text-decoration: underline;
  text-decoration-color: #01009a;

}

h4 {
  font-family: 'Arial', sans-serif;
}
</style>
