<template>
  <div ref="mySection">
    <v-tabs v-model="tab" align-tabs="center" class="mt-3 text-white" color="black"
            style="height: 100%">
      <v-tab :value="0" style="color: white">Erstellen</v-tab>
      <v-tab :value="1" style="color: white">Löschen</v-tab>
    </v-tabs>

    <v-window v-model="tab">
      <v-window-item value="0">
        <v-container fluid>
          <v-row style="width: 100%">
            <v-col class="pa-0 pt-10">
              <v-card class="mx-auto  pa-5"
                      style="width: 90%;background-color: rgba(255, 255, 255, 0.9); border-radius: 10px;">
                <v-row class="justify-center mt-3">
                  <v-col class="d-flex justify-center" cols="4">
                    <v-text-field v-model="ueberschrift" label="Überschrift" variant="outlined"/>
                  </v-col>
                  <v-col class="d-flex justify-center" cols="4">
                    <v-text-field v-model="unterUeberschrift" label="Unterüberschrift" variant="outlined"/>
                  </v-col>
                  <v-col class="d-flex justify-center" cols="4">
                    <v-text-field v-model="autor" label="Autor" variant="outlined"/>
                  </v-col>
                  <v-col :cols="imageBase64 && file? 6 : 12 " class="d-flex justify-center">
                    <v-file-input
                        v-model="file"
                        accept="image/*"
                        label="Wähle ein Bild aus"
                        @change="handleFileInput"
                    ></v-file-input>
                  </v-col>
                  <v-col v-if="imageBase64 && file" class="d-flex justify-center" cols="6">
                    <img :src="imageBase64" alt="Vorschau" height="100"/>
                  </v-col>
                  <v-col v-for="(absatzObjekt, index) in absaetze" :key="index"
                         class="d-flex justify-center align-center" cols="12">
                    <v-textarea
                        v-model="absatzObjekt.inhalt"
                        auto-grow
                        class="flex-grow-1"
                        counter
                        label="Absatz"
                        variant="outlined"
                    />
                    <v-icon v-if="absaetze.length > 1" color="red" style="cursor: pointer;"
                            @click="removeAbsatz(index)">
                      mdi-delete
                    </v-icon>
                  </v-col>
                  <v-col class="d-flex justify-center center align-center mt-n5">
                    <v-btn @click="absaetze.push({inhalt: null})">
                      Weiterer Absatz
                    </v-btn>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col v-if="editAktuelles" class="d-flex justify-center center align-center mt-n5">
                    <v-btn @click="clear(true)">
                      Neuen Beitrag erstellen
                    </v-btn>
                  </v-col>
                  <v-col v-if="!editAktuelles" class="d-flex justify-center center align-center mt-n5">
                    <v-btn @click="create">
                      Speichern
                    </v-btn>
                  </v-col>
                  <v-col v-if="editAktuelles" class="d-flex justify-center center align-center mt-n5">
                    <v-btn @click="update">
                      Aktualisieren
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-window-item>

      <v-window-item value="1">
        <v-row class="justify-center mt-3" style="width: 100%;">
          <v-col cols="10">
            <v-data-table-virtual :items="filteredAktuelles" fixed-header height="500" items-per-page="7">
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.id }}</td>
                  <td>{{ item.ueberschrift }}</td>
                  <td>{{ item.unterueberschrift }}</td>
                  <td>{{ item.autor }}</td>
                  <td>
                    <v-img :src="item.bild" alt=""/>
                  </td>
                  <td>
                    <Icon class="mt-2" icon="bxs:edit" style="font-size: 30px" @click="edit(item)"/>
                  </td>
                  <td>
                    <v-icon color="red" style="font-size: 30px" @click="deleteMethod(item)">mdi-delete</v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table-virtual>
          </v-col>
        </v-row>
      </v-window-item>
    </v-window>
  </div>
</template>

<script>
import axios from "axios";
import {mapGetters} from "vuex";
import {Icon} from "@iconify/vue";

export default {
  components: {Icon},
  data() {
    return {
      tab: 0,
      ueberschrift: null,
      unterUeberschrift: null,
      autor: null,
      absaetze: [
        {
          inhalt: null
        }
      ],
      file: null,
      imageBase64: '',
      editAktuelles: false,
      selectedId: null
    };
  },
  computed: {
    ...mapGetters(['aktuelles']),
    filteredAktuelles() {
      return this.aktuelles.map(item => {
        return {
          id: item.id,
          ueberschrift: item.ueberschrift,
          unterueberschrift: item.unterueberschrift,
          autor: item.autor,
          bild: item.bild
        };
      });
    }
  },
  mounted() {
    this.get()
  },
  methods: {
    async create() {
      try {
        await axios.post('/aktuelles', {
          ueberschrift: this.ueberschrift,
          unterueberschrift: this.unterUeberschrift,
          bild: this.file ? this.imageBase64 : null,
          autor: this.autor,
          absaetze: this.absaetze

        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

        await this.get();
        this.clear(false);
        this.tab = 1
      } catch (e) {
        alert("Es ist ein Fehler aufgetreten. Versuchen Sie es erneut. Falls das Problem weiterhin besteht, kontaktieren Sie bitte den Administrator.");
      }
    },
    clear(isNewAktuelles) {
      if (isNewAktuelles) {
        this.editAktuelles = false
        this.selectedId = null
      }
      this.ueberschrift = null,
          this.unterUeberschrift = null,
          this.autor = null
      this.absaetze = [
        {
          inhalt: null
        }
      ]
      this.imageBase64 = null
      this.file = null
      this.$refs.mySection.scrollIntoView({
        behavior: 'smooth', // Für sanftes Scrollen
        block: 'start'      // Scrollt das Element zum oberen Rand des sichtbaren Bereichs
      });
    },
    async deleteMethod(item) {
      try {
        await axios.delete('/aktuelles/' + item.id, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        await this.get();
      } catch (e) {
        alert("Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut. Falls das Problem weiterhin besteht, kontaktieren Sie bitte den Administrator.");
      }
    },
    async get() {
      const response = await axios.get('/aktuelles', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      this.$store.state.aktuelles = response.data;
    },
    handleFileInput() {
      console.log(this.file)
      if (this.file) {
        this.convertToBase64(this.file);
      }
    },
    convertToBase64(file) {
      const reader = new FileReader();

      reader.onload = () => {
        this.imageBase64 = reader.result;
      };

      reader.readAsDataURL(file);
    },
    edit(item) {
      this.selectedId = item.id;

      const selectedItem = this.aktuelles.find(aktuellesItem => aktuellesItem.id === item.id);

      if (!selectedItem) {
        console.error('Item nicht gefunden');
        return;
      }

      this.editAktuelles = true;
      this.ueberschrift = selectedItem.ueberschrift;
      this.unterUeberschrift = selectedItem.unterueberschrift;
      this.autor = selectedItem.autor;
      this.imageBase64 = selectedItem.bild;
      this.file = {name: 'Wählen Sie ein neues Bild aus'};
      this.tab = 0;

      console.log(selectedItem)
      // Sortiere die Absätze nach ID
      this.absaetze = selectedItem.absaetze.slice().sort((a, b) => a.id - b.id);
      this.$refs.mySection.scrollIntoView({
        behavior: 'smooth', // Für sanftes Scrollen
        block: 'start'      // Scrollt das Element zum oberen Rand des sichtbaren Bereichs
      });

    },
    async update() {
      try {
        // Suche das Item mit der ID aus selectedId
        const id = this.selectedId;

        if (!id) {
          console.error('Keine ID ausgewählt.');
          return;
        }


        const updateData = {
          id: id,
          ueberschrift: this.ueberschrift,
          unterueberschrift: this.unterUeberschrift,
          autor: this.autor,
          absaetze: this.absaetze,
          bild: this.file ? this.imageBase64 : null
        };
        const response = await axios.put(`/aktuelles/${id}`, updateData, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

        console.log('Update erfolgreich:', response.data);
        this.tab = 1
        this.clear(true)
        await this.get()
      } catch (error) {
        console.error('Fehler beim Aktualisieren:', error);
        // Optional: Zeige eine Fehlermeldung an den Benutzer an
      }
    },
    removeAbsatz(index) {
      this.absaetze.splice(index, 1);
    },
  }
};
</script>

<style scoped>
.v-row {
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
}

.container {
  background-color: rgba(255, 255, 255, 0.9);
  border: 2px solid black;
  padding: 20px;
  width: 70%;
  margin: 50px auto;
  position: absolute;
  top: 0px;
  overflow: hidden;
  border-radius: 10px;
}

.image {
  float: left;
  margin-right: 20px;
}

.text {
  text-align: justify;
}

.v-card {
  background-color: rgba(255, 255, 255, 0.9) !important;
}
</style>
